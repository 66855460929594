import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ACTIVITY_TYPES } from 'state-domains/constants';

import { buildLoadSampleUrl, buildLoadSamplePointsUrl } from '../../../network/urls';
import { sendRetrieveRequest, convertToCamel } from '../../../utils';
import { Sample } from '../types';

export const loadSample = (sampleId: string, type: ACTIVITY_TYPES): Observable<Sample> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildLoadSampleUrl(sampleId)
            : buildLoadSamplePointsUrl(sampleId);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<Sample>) => {
            const result = convertToCamel<Sample>(response);
            return observableOf(result);
        }),
    );
};
